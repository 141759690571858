import "@babel/polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";


import Es6Promise from "es6-promise";
import "./assets/css/common.scss";
import {
  Form,
  FormItem, Input, Button, Container,
  Header,
  Aside,
  Main,
  Footer, Dropdown,
  DropdownMenu,
  DropdownItem, Menu,
  Submenu,
  MenuItem, Dialog,
  MenuItemGroup, Select,
  Option, Checkbox,
  CheckboxButton, Progress,
  CheckboxGroup, Tag,
  OptionGroup, DatePicker,
  TimeSelect, Table, MessageBox,
  Message, Switch, Upload,
  TableColumn, Pagination, TimePicker, Image,Loading, Carousel,
  CarouselItem,Drawer
} from "element-ui";
import local from "./utils/localstorage";
import request from "./utils/request";

Vue.use(Form).use(FormItem).use(Input).use(Button).use(Dialog)
  .use(Container).use(Header).use(Aside).use(Main).use(Footer).use(Dropdown)
  .use(DropdownMenu).use(DropdownItem).use(Menu).use(Submenu).use(MenuItem)
  .use(MenuItemGroup).use(Select).use(Option).use(OptionGroup).use(DatePicker).use(Tag)
  .use(TimeSelect).use(Table).use(TableColumn).use(Pagination).use(Switch).use(Progress)
  .use(Checkbox).use(CheckboxButton).use(CheckboxGroup).use(Upload).use(TimePicker)
  .use(Image).use( Carousel).use(CarouselItem).use(Drawer);



Vue.use(local);
import * as echarts from "echarts";

Vue.prototype.$echarts = echarts;

Vue.use({
  install(Vue) {
    Vue.prototype.$local = local;
  }
});

Vue.use(request);

Vue.prototype.$ajax = axios;
Vue.prototype.$get = request.get;
Vue.prototype.$post = request.post;
Vue.prototype.$put = request.put;
Vue.prototype.$del = request.delete;
Vue.prototype.$postJson = request.postJson;
Vue.prototype.$putJson = request.putJson;
Vue.prototype.$export = request.export;
Vue.prototype.$download = request.download;
Vue.prototype.$upload = request.upload;
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;



Vue.config.productionTip = false;

Es6Promise.polyfill();

import * as filters from "./utils/timeFormatFilter";

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
