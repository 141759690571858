<template>
  <div class="bg flex">
    <div class="login flex">
      <div class="left">
        <div class="logo flex"><img src="../assets/img/logo.png" alt=""></div>
      </div>
      <div class="right flex">
        <h1>银星车辆管理平台</h1>
        <el-form ref="form" :model="form" class="formLogin" @keyup.enter.native="onSubmit">
          <el-form-item prop="name">
            <el-input v-model="form.account" placeholder="请输入账号"></el-input><!--type="tel"-->
          </el-form-item>
          <el-form-item prop="password"
                        :rules="[{ required: true, message: '密码不能为空'}]">
            <el-input type="password" v-model="form.password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input placeholder="请输入验证码"
                      v-model="form.code">
              <el-button slot="append">
                <img :src="imgSrc" @click="reloadCode">
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="loginBtn" @click="onSubmit">登录</el-button>
          </el-form-item>
          <p class="tips">忘记密码请联系后台管理员进行修改</p>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>

  import moment from 'moment'
  moment.locale('zh-cn')
export default {
  name: "login",
  data() {
    /*const validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入电话号码"));
      } else if (!this.isvalidPhone(value)) {
        callback(new Error("请输入正确的11位手机号码"));
      } else {
        callback();
      }
    };*/
    return {
      form: {
        account: "",
        password: "",
        code: "",
        codeToken:''
      },
      imgSrc:'',
      data:[]
      /*rules: {
        name: [
          { required: true, trigger: "blur", validator: validPhone },
          { max: 11, message: "请输入正确的手机号码", trigger: "blur" }
        ]
      }*/
    };
  },
  mounted() {
    this.reloadCode()
  },
  methods: {
    /*isvalidPhone(str) {
      const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
      return reg.test(str);
    },*/

    reloadCode(){
      this.$ajax.get(this.$local.baseURL()+'code/captcha/login',{
        t:new Date().getTime()
      }).then(res=>{
        this.imgSrc = res.data.data.base64
        this.form.codeToken = res.data.data.token
      })
    },
    onSubmit() {
      this.$post('/login',this.form).then(res=>{
        if (res.data.code && res.data.code == 200){
          localStorage.setItem("accessToken",res.data.data.accessToken)
          localStorage.setItem("lastLoginTime",moment().format("YYYY-MM-DD HH:mm:ss"))
          localStorage.setItem("expiresIn",res.data.data.expiresIn)
          localStorage.setItem("currentUser",JSON.stringify(res.data.data.authUser))
          localStorage.setItem("userId",res.data.data.authUser.id)
          this.$router.push({ name: "index" ,params: {isLogin: 1}})
        }else {
          this.$message.error(res.data.msg)
          this.reloadCode()
        }
      })
      //this.$router.push({ name: "index" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/css/login";
</style>
