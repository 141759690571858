import axios from 'axios'
import moment from 'moment'
moment.locale('zh-cn')
import {Message} from "element-ui";


//统一配置
let OKR_REQUEST = axios.create({

    baseURL: process.env.VUE_APP_BASE_PATH,
    responseType:'json',
})

//拦截请求
OKR_REQUEST.interceptors.request.use((config)=>{

    let expireTime =localStorage.getItem("expiresIn")
    let lastLoginTime =localStorage.getItem("lastLoginTime")
    let now = moment().format("YYYY-MM-DD HH:mm:ss")

    // 让token早10秒种过期，提升“请重新登录”弹窗体验
    if(moment(now).diff(lastLoginTime,"seconds") > expireTime && config.url !== '/login'){
        Message.error( "登录已过期，请重新登录");
        localStorage.clear()
        axios.get(process.env.VUE_APP_BASE_PATH+'logout',{
            userId:localStorage.getItem("userId")
        }).then(()=>{
            location.href="/"
            //location.href="/parkinglot/"
        })
        return;
    }
    // 有 token就带上
    if(localStorage.accessToken){
        config.headers.token = localStorage.accessToken
    }
    return config
    },(error)=>{
        Message.error("系统异常："+error);
    })


// 拦截响应
OKR_REQUEST.interceptors.response.use((config) => {
    return config
}, (error) => {
    if (error.response) {
        let errorMessage = error.response.data === null ? '系统内部异常，请联系网站管理员' : error.response.data.message
        switch (error.response.status) {
            case 404:
                Message.error('很抱歉，资源未找到')
                break
            case 403:
            case 401:
                Message.error('很抱歉，您无法访问该资源，可能是因为没有相应权限或者登录已失效');
                axios.get(process.env.VUE_APP_BASE_PATH+'logout',{
                    userId:localStorage.getItem("userId")
                }).then(()=>{
                    location.href="/"
                    //location.href="/parkinglot/"
                })
                break
            case 500:
                Message.error('很抱歉，资源未找到')
                break
            default:
                Message.error(errorMessage)
                break
        }
    }
    return Promise.reject(error)
})


const request = {
    post(url,params){
        return OKR_REQUEST.post(url,params,{
            transformRequest:[(params)=>{
                let result = ''
                Object.keys(params).forEach((key)=>{
                    if(!Object.is(params[key],undefined)&& !Object.is(params[key],null)){
                        result += encodeURIComponent(key)+'='+encodeURIComponent(params[key])+'&'
                    }
                })
                return result
            }],
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
               // 'Content-Type':'application/json; charset=UTF-8'
            }
        })
    },
    postJson(url,params){
        return OKR_REQUEST.post(url,params,{
            headers:{
                 'Content-Type':'application/json; charset=UTF-8'
            }
        })
    },
    putJson(url,params){
        return OKR_REQUEST.put(url,params,{
            headers:{
                'Content-Type':'application/json; charset=UTF-8'
            }
        })
    },
    put(url,params){
        return OKR_REQUEST.put(url,params,{
            transformRequest:[(params)=>{
                let result = ''
                if(params!=null){
                    Object.keys(params).forEach((key)=>{
                        if(!Object.is(params[key],undefined)&& !Object.is(params[key],null)){
                            result += encodeURIComponent(key)+'='+encodeURIComponent(params[key])+'&'
                        }
                    })
                }
                return result
            }],
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            }
        })
    },
    get(url,params){
       let _params
        if(Object.is(params,undefined)){
            _params = ''
        }else{
            _params = '?'
            for (let key in params){
               // params.hasOwnProperty(key)

                if(  Object.prototype.hasOwnProperty.call(params, key) && params[key]!== null){
                    _params += `${key}=${params[key]}&`
                }
            }
        }
        return OKR_REQUEST.get(`${url}${_params}`)
    },
    delete(url,params){
        let _params
        if(Object.is(params,undefined)){
            _params = ''
        }else{
            _params = '?'
            for (let key in params){
                if(Object.prototype.hasOwnProperty.call(params, key) && params[key]!== null){
                    _params += `${key}=${params[key]}&`
                }
            }
        }
        return OKR_REQUEST.delete(`${url}${_params}`)
    },
    upload(url,params){
        return OKR_REQUEST.post(url,params,{
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })

    },
   download(url,params,filename){
        if(!filename){filename='download'};

        return OKR_REQUEST.post(url,params,{
            transformRequest:[(params)=>{
                let result = ''
                Object.keys(params).forEach((key)=>{
                    if(!Object.is(params[key],undefined)&& !Object.is(params[key],null)){
                        result += encodeURIComponent(key)+'='+encodeURIComponent(params[key])+'&'
                    }
                })
                return result
            }],
            responseType: 'blob'
        }).then((r)=>{
            const content = r.data
            // 字符内容转变成blob地址
            const blob = new Blob([content],{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'})
                //判断属性属于对象    download属性兼容性不好
            if('download' in document.createElement('a')){
                const elink = document.createElement('a')
                elink.download = filename;
                elink.style.display ='none'
                elink.href = URL.createObjectURL(blob)
                document.body.appendChild(elink)
                elink.click()
                URL.revokeObjectURL(elink.href)
                document.body.removeChild(elink)
            }else{
                //Internet Explorer 10 的  msSaveBlob 和 msSaveOrOpenBlob 方法
                // 允许用户在客户端上保存文件
                navigator.msSaveBlob(blob,filename)
            }
        }).catch((r)=>{
            console.error(r)
        })
    },
    export(url,params ={}){
        return OKR_REQUEST.post(url,params,{
            transformRequest:[(params)=>{
                let result = ''
                Object.keys(params).forEach((key)=>{
                    if(!Object.is(params[key],undefined)&& !Object.is(params[key],null)){
                        result += encodeURIComponent(key)+'='+encodeURIComponent(params[key])+'&'
                    }
                })
                return result
            }],
            responseType: 'blob'
        }).then((r)=>{
            const content = r.data
            // 字符内容转变成blob地址
            const blob = new Blob([content])
            const fileName = params['filename']+`.xlsx`
                //判断属性属于对象    download属性兼容性不好
            if('download' in document.createElement('a')){
                const elink = document.createElement('a')
                elink.download = fileName
                elink.style.display ='none'
                elink.href = URL.createObjectURL(blob)
                document.body.appendChild(elink)
                elink.click()
                URL.revokeObjectURL(elink.href)
                document.body.removeChild(elink)
            }else{
                //Internet Explorer 10 的  msSaveBlob 和 msSaveOrOpenBlob 方法
                // 允许用户在客户端上保存文件
                navigator.msSaveBlob(blob,fileName)
            }
        }).catch(()=>{

        })
    }
}

export default request








