<template>
	<el-container class="container">
		<el-header height="64px">
			<div class="headLeft flex">
				<div class="isCollapse flex" @mouseover="drawer=true"><img src="../assets/img/menu.png" alt=""></div>
				<div class="logo flex">
					<img src="../assets/img/logo.png" alt="">
				</div>
			</div>
			<h3 class="title">{{ title }}</h3>
			<div class="dropDown flex">
				<h4>{{ systemTime }}</h4>
				<el-dropdown @command="handleCommand">
					<span>{{ name }}<i class="el-icon-arrow-down el-icon--right"></i></span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="a">退出</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</el-header>
		<el-container class="adminBg">
			<el-main>
				<router-view />
				<el-footer height="20px">{{ data }}@ 银星科技 备案号：
					<a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备19101993号</a>
				</el-footer>
			</el-main>
		</el-container>
		<el-drawer size="240px"
							 title="我是标题" direction="ltr"
							 :visible.sync="drawer"
							 :with-header="false">
			<el-aside>
				<el-menu unique-opened :default-active="this.$route.path" router
								 text-color="#212844"
								 class="el-menu-demo">
					<template v-for="menu in  menuList">
						<el-submenu :index="menu.path" :key="menu.orderNum" v-if="menu.children">
							<!--							&lt;!&ndash; 子菜单栏信息 &ndash;&gt;-->
							<template slot="title">
								<div class="menuAct">
									<img :src="menu.icon" alt="" class="icon">
									<span slot="title">{{ menu.name }}</span>
								</div>
							</template>
							<!--							&lt;!&ndash; el-menu-item为具体的菜单项 &ndash;&gt;-->
							<el-menu-item-group v-for="(arr,ind) in  menu.children" :key="ind">
								<el-menu-item :index="arr.path">
									<span slot="title" style="margin-left: 20px;">{{ arr.name }}</span>
								</el-menu-item>
							</el-menu-item-group>
						</el-submenu>
						<el-menu-item :key="menu.orderNum" :index="menu.path " v-else>
							<div class="menuAct">
								<img :src="menu.icon" alt="" class="icon">
								<span slot="title">{{ menu.name }}</span>
							</div>
						</el-menu-item>
					</template>
				</el-menu>
			</el-aside>
		</el-drawer>
	</el-container>


</template>

<script>
import moment from "moment";

moment.locale("zh-cn");
export default {
	name: "index",
	data() {
		return {
			title: "银星车辆管理平台",
			name: JSON.parse(localStorage.getItem("currentUser")).name,
			data: new Date().getFullYear(),
			menuList: [],
			defaultOpeneds: "",
			systemTime: moment(new Date()).format("YYYY-MM-DD"),

			isLogin: this.$route.params.isLogin,
			drawer: false
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			/*this.$ajax.get("./menu.json").then(res => {
				this.menuList = res.data.menuList;
			});*/
			this.$get("/car/authPermission/menu").then(res => {
				this.menuList = res.data.data;
				if (this.isLogin) {
					this.$router.push(res.data.data[0].path);
				}
			});
		},
		handleCommand() {
			this.$get("/logout", {
				userId: localStorage.getItem("userId")
			}).then(() => {
				localStorage.clear();
				this.$router.push("/");
			});
		}
	}
};
</script>

<style lang="scss" scoped>
@import "../assets/css/index";
</style>
