import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../components/login.vue";
import Index from "../components/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login
  }, {
    path: "/index",
    name: "index",
    component: Index,
    children: [
      {
        // 管理者
        path: "/index/adminAccount",
        name: "adminAccount",
        component: () => import("../views/account/adminAccount")
      }, {
        // 普通员工
        path: "/index/account",
        name: "account",
        component: () => import("../views/account/account")
      }, {
        // 会员信息
        path: "/index/member",
        name: "member",
        component: () => import("../views/member/member")
      }, {
        // 会员类型
        path: "/index/memberType",
        name: "memberType",
        component: () => import("../views/member/memberType")
      }, {
        // 抄单记录
        path: "/index/copyingRecords",
        name: "copyingRecords",
        component: () => import("../views/member/copyingRecords")
      },{
        // 违规通知记录
        path: "/index/violation",
        name: "violation",
        component: () => import("../views/member/violation")
      }, {
        // 黑名单
        path: "/index/blackList",
        name: "blackList",
        component: () => import("../views/member/blackList")
      },
      {
        // 车辆信息
        path: "/index/vehicleInfo",
        name: "vehicleInfo",
        component: () => import("../views/vehicleInfo/vehicleInfo")
      }, {
        // 收入明细
        path: "/index/incomeDetails",
        name: "incomeDetails",
        component: () => import("../views/incomeDetails/incomeDetails")
      }, {
        // 停车场管理
        path: "/index/parkingMana",
        name: "parkingMana",
        component: () => import("../views/parkingMana/parkingMana")
      }, {
        // 用户管理
        path: "/index/userMana",
        name: "userMana",
        component: () => import("../views/sysSettings/userMana")
      }, {
        // 角色管理
        path: "/index/roleMana",
        name: "roleMana",
        component: () => import("../views/sysSettings/roleMana")
      }, {
        // 导入记录
        path: "/index/importRecords",
        name: "importRecords",
        component: () => import("../views/sysSettings/importRecords")
      }, {
        // 统计分析 --  客户
        path: "/index/client",
        name: "client",
        component: () => import("../views/statistics/client")
      }, {
        // 统计分析 --  客户
        path: "/index/areaAmount",
        name: "areaAmount",
        component: () => import("../views/statistics/areaAmount")
      }, {
        // 统计分析 --  过夜
        path: "/index/overnight",
        name: "overnight",
        component: () => import("../views/statistics/overnight")
      }, {
        // 统计分析 --  少于
        path: "/index/lessThan",
        name: "lessThan",
        component: () => import("../views/statistics/lessThan")
      }, {
        // 抄单详情
        path: "/index/copyingDetail",
        name: "copyingDetail",
        component: () => import("../views/member/copyingDetail")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
