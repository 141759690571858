let local ={
    save(key,value){
        //要转换的 JavaScript 值（通常为对象或数组）
        localStorage.setItem(key,JSON.stringify(value))

    },
    get(key,defaultValue ={}){
        //JSON.parse() 方法用于将一个 JSON 字符串转换为对象。

        if(localStorage.getItem(key)===undefined || localStorage.getItem(key)=== null || localStorage.getItem(key).length <=0 ||localStorage.getItem(key)==="undefined" ||localStorage.getItem(key)=== "null"){

            return defaultValue
        }else{
            return  JSON.parse(localStorage.getItem(key))|| defaultValue
        }

    },
    remove(key) {
        localStorage.removeItem(key)
    },
    clear(){
        localStorage.clear()    //清除localStorage中所有的数据项。
        sessionStorage.clear()
    },
    baseURL(){
        return process.env.VUE_APP_BASE_PATH;
    }
}

export default local
